import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Link } from "@nulogy/components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Style Props`}</h1>
    <p>{`Nulogy's components use styled-system to apply groups of style props to different types of components. These props correspond to their css equivalents and allow individual manipulation of a component's style.`}</p>
    <h2>{`Prop categories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`space`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin, marginTop, marginRight, marginBottom, marginLeft, marginX, marginY, padding, paddingTop, paddingRight, paddingBottom, paddingLeft, paddingX, paddingY, m, mt, mr, mb, ml, mx, my, p, pt, pr, pb, pl, px, py,`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`layout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width, height, minWidth, minHeight, maxWidth, maxHeight, display, verticalAlign, size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`typography`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily, fontSize, fontWeight, lineHeight, letterSpacing, fontStyle, textAlign`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`colour`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color, backgroundColor, bg`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`border`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`border, borderWidth, borderStyle, borderColor, borderRadius, borderTop, borderRight, borderBottom, borderLeft, borderX, borderY,`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`position, zIndex, top, right, bottom, left`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`For more information on each of these props, see the `}<a parentName="p" {...{
        "href": "https://github.com/styled-system/styled-system/blob/master/docs/table.md"
      }}>{`styled-system docs`}</a>{`.`}</p>
    <h2>{`Usage`}</h2>
    <p>{`To see which props each component includes, see that component's documentation. But in general, the following rules apply:`}</p>
    <ul>
      <li parentName="ul">{`Block-level components (Alerts, Buttons, Cards, etc.) have access to space props.`}</li>
      <li parentName="ul">{`Text components (Text, Headings, Link) also have access to typography and colour props.`}</li>
      <li parentName="ul">{`The Box component has access to layout props and can be used to add borders, colours, and boxShadows around anything.`}</li>
    </ul>
    <Alert mdxType="Alert">
  Note that inputs don't have access to any of these props because they're
  already pre-composed. To stack multiple inputs with proper spacing on top of
  each other, wrap them in the{" "}
  <Link href="https://nulogy.design/components/form" mdxType="Link">Form</Link> component.
    </Alert>
    <h2>{`Theme`}</h2>
    <p>{`Styled-system will check the `}<a parentName="p" {...{
        "href": "/theme"
      }}>{`theme`}</a>{` and use that if a key matches, e.g setting a `}<inlineCode parentName="p">{`textColor`}</inlineCode>{` to `}<inlineCode parentName="p">{`blue`}</inlineCode>{` will display Nulogy's `}<inlineCode parentName="p">{`#216beb`}</inlineCode>{`, instead of the html default. If a key can't be found, it will be processed as a CSS value.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      